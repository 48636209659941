main#main {
	.feature {
		display: block;
		padding: 80px 0;
		margin: 0;

		h2 {
			font-size: 24px;
			font-weight: 400;
			margin: 0 0 10px 0;
			color: #333;

			&:before {
			    left: -10px;
			}
			&:after {
			    right: -10px;
			    
			}
			&:before, &:after {
				display: inline-block;
			    content: "";
			    border-bottom: 1px solid #2f3948;
			    position: relative;
			    width: 25px;
			    top: -8px;
			    -o-transition: all 0.3s ease;
			    transition: all 0.3s ease;
			}
		}

		.subtitle {
			font-size: 16px;
			font-weight: 300;
			color: #666;
			margin: 0 0 50px 0;
		}

		h2, .subtitle {
			display: block;
			line-height: 24px;
			text-align: center;
		}


		.service-tabs {
			border: none;
			.nav-item {
				position: relative;
				padding-bottom: 18px;
				border-bottom: 5px solid #eaebec;
				transition: all .3s ease-in-out;
				-webkit-transition: all .3s ease-in-out;

				.nav-link {
					font-size: 16px;
					font-weight: 300;
					line-height: 48px;
					border: 0;
					padding: 0;
					border-radius: 0;
					text-align: center;
					color: #dbdfe3;
					cursor: pointer;

					i {
						display: block;
						font-size: 68px;
					}
				}

				&.active {
					border-color: #2f3948;

					.nav-link {
						font-weight: 400;
						color: #2f3948;
					}

					&:after {
						content: " ";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 7.5px 0 7.5px;
						border-color: #2f3948 transparent transparent transparent;
						position: absolute;
						top: 100%;
						margin-top: 5px;
						left: 50%;
						margin-left: -7.5px;
					}
				}

				&+ .nav-item {
					margin-left: 0;
				}
			}
		}

		.service-tabs-content {
			display: block;
			padding: 35px 0 25px 0;

			h3 {
				font-size: 18px;
				font-weight: 400;
				color: #2f3948;
				margin: 0 0 10px 0;
			}

			p {
				font-size: 13px;
				font-weight: 300;
				color: #a1a1a1;
			}

			ul.webicons {
				display: block;
				list-style: none;

				li {
					.icon-container {
						float: left;
						width: 75px;
						height: 75px;
						border: 4px solid #2f3948;
						border-radius: 50%;
						text-align: center;
						margin-right: 25px;
						color: #2f3948;
						transition: all .3s ease-in-out;
						-webkit-transition: all .3s ease-in-out;

						&:hover {
							color: white;
							background: #2f3948;
						}

						i {
							font-size: 28px;
							line-height: 68px;
						}
					}
					.contents {
						padding-left: 100px;

						span {
							font-size: 16px;
							font-weight: 400;
							color: #2c2f33;
							text-transform: uppercase;
						}
						p {
							font-size: 13px;
							font-weight: 300;
							color: #a1a1a1;
						}
					}
				}
			}
		}

		article.news {
			display: block;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
			background-color: #fafafa;
			margin-top: 25px;

			header {
				text-align: center;

				.news-image {
					width: 100%;
					height: 200px;

					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
				h2 {
					display: block;
					font-size: 16px;
					font-weight: 400;
					text-transform: uppercase;
					background-color: #2f3948;
					color: white;
					padding: 12px 15px;
				}
			}
			main {
				font-size: 13px;
				font-weight: 300;
				line-height: 18px;
				opacity: 0.75;
				color: #484848;
				margin: 0 25px;
				padding: 25px 0;
				border-bottom: 1px solid #d6d6d6;
				text-align: center;
			}
			footer {
				display: block;
				padding: 25px;
				text-align: center;

				.news-btn {
					display: inline-block;
					margin-left: 15px;
					background: #2f3948;
					color: white;
					border-radius: 25px;
					font-size: 15px;
					font-weight: bold;
					padding: 10px 25px;
					text-decoration: none;
					border: 4px solid white;

					transition: all .3s ease-in-out;
					-webkit-transition: all .3s ease-in-out;

					&:hover {
						border-color: #2f3948;
						color: #2f3948;
						background: white;
					}
				}
			}
		}

		.testimonials {
			border-bottom: 4px double rgba(255,255,255,.2);

			.testimonial {
				padding: 25px;
				border-right: 4px double rgba(255,255,255,.2);

				p {
					font-size: 13px;
					line-height: 24px;
					color: white;
					text-align: center;
				}

				.img-container {
					width: 100%;
					height: 50px;
					white-space: nowrap;
					margin-bottom: 25px;

					.img-wrap {
						display: inline-block;
					    height: 100%;
					    max-height: 100%;
					    vertical-align: middle;

					    img {
					    	vertical-align: middle;
					    	max-height: 50px;
					    	max-width: 100%;
					    	width: auto;
					    }
					}
				}

				&:last-child {
					border-right: none;
				}
			}

			&.bottom,
			&:last-child {
				border-bottom: none;
			}
		}

		&.bg {
			position: relative;

			&:before {
			    content: " ";
			    position: absolute;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 0;
			    background-image: url(../images/bg.jpg);
			    background-repeat: none;
			    background-attachment: scroll;
			    background-position: center center;
			    -webkit-background-size: cover;
			    -moz-background-size: cover;
			    background-size: cover;
			    -o-background-size: cover;
			    z-index: -2;
			}

			&:after {
				content: " ";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(47,57,72,.6);
				z-index: -1;
			}

			h2 {
				color: white;

				&:before, &:after {
					border-color: white;
				}
			}
			.subtitle {
				color: rgba(255,255,255,.75);
			}
		}
		

	}

	.hero-container {
		display: block;
		width: 100%;
		background: #2f3948;
		color: white;
		padding: 30px 15px;
		font-size: 24px;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;

		.hero-btn {
			display: inline-block;
			margin-left: 15px;
			background: white;
			color: #2f3948;
			border-radius: 25px;
			font-size: 15px;
			font-weight: bold;
			padding: 10px 25px;
			text-decoration: none;
			border: 4px solid white;

			transition: all .3s ease-in-out;
			-webkit-transition: all .3s ease-in-out;

			&:hover {
				background: #2f3948;
				color: white;
			}
		}

		&.invert {
			background: white;
			color: #2f3948;

			.hero-btn {
				color: white;
				background: #2f3948;
				border-color: #2f3948;

				&:hover {
					color: #2f3948;
					background: white;
				}
			}
		}

		span {
			span {
				font-weight: bold;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	main#main {
		.hero-container {
			> span {
				display: block;
			}
			.hero-btn {
				margin-top: 10px;
			}
		}
		.feature {
			.testimonials {
				.testimonial {
					border-right: none;
					border-bottom: 4px double rgba(255,255,255,.2);

					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	main#main {
		.feature {
			.testimonials {
				.testimonial {
					border-bottom: 4px double rgba(255,255,255,.2);

					&:last-child, &:nth-last-child(2) {
						border-bottom: none;
					}
				}
				.testimonial:nth-child(odd) {
					border-right: 4px double rgba(255,255,255,.2);
				}
				.testimonial:nth-child(even) {
					border-right: none;
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	main#main {
		.feature {
			.service-tabs-content {
				ul.webicons {
					li {
						margin-bottom: 40px;

						.icon-container {
							float: none;
							display: block;
							position: relative;
							left: 50%;
							margin-left: -37.5px;
							margin-bottom: 15px;
						}
						.contents {
							padding-left: 0;

							span, p {
								display: block;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}