/* Colour Theme Global Skin colours passed in from above variables */

/* Each pallete specific colours */
$skin-color: '';
$config: (
    skins: (

       pastell: (
           primary: #38a7c8,
           secondary: #93dda4,
           tertiary: #d1fcb7,
           quaternary: #faf9a6,
           quinary: #fcf319,
       ),

       deep-purple: (
           primary: #452271,
           secondary: #6441A5,
           tertiary: #2a0845,
       ),

        proactive-systems: (
            primary: #2f3948,
            secondary: #185a9d,
            tertiary: #43cea2,
            quaternary: #00c853,
            quinary: #d50000,
        ),

        mark: (
            primary: #ca2828,
            secondary: #3b3a3a,
            tertiary: #e9c7c7,
        ),

        flat: (
            primary: #34495E,
            secondary: #2980B9,
            tertiary: #ECF0F1,
            quaternary: #E67E22,
            quinary: #27AE60,
        ),

        exotel: (
            primary: #40BC86,
            secondary: #199EC7,
            tertiary: #FCB410,
        ),

        the-greens: (
            primary: #1BBC9B,
            secondary: #16A086,
            tertiary: #1BA39C,
            quaternary: #0B8389,
            quinary: #0F6177,
        ),

        retro: (
            primary: #4CD4B0,
            secondary: #FFFCE6,
            tertiary: #EDD834,
            quaternary: #F24D16,
            quinary: #7D1424,
        ),
    )
)
;


@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);
    @if ($length > 1) {
        $rest: ();
        @for $i from 2 through $length {
            $rest: append($rest, nth($keys, $i))
        }
        @return map-fetch($value, $rest)
    } @else {
        @return $value;
    }
}

@mixin build-theme ($config: $config) {
    @each $skin in map-keys(map-get($config, skins)) {
        &.theme-#{$skin} {
            $skin: $skin !global;
            @content
        }
    }
}
