body {

    @include build-theme() {
        .body-title {
            color: map-fetch($config, skins $skin primary  );
        }

        .btn-primary {
            background: map-fetch($config, skins $skin primary  );
            border-color: map-fetch($config, skins $skin primary  );
            color: white;

            &:hover,
            &:focus,
            &:active {
                background: white;
                border-color: map-fetch($config, skins $skin primary  );
                color: map-fetch($config, skins $skin primary  );
            }
        }

        header#header {
        	border-bottom: 2px solid map-fetch($config, skins $skin primary  );

            .navbar {
        		ul {
        			.nav-item {
        				&.active,
        				&:hover {
        					.nav-link {
        						color: map-fetch($config, skins $skin primary  );
        					}
        				}
        			}
        		}
        	}
        }

        @media (max-width: 991px) {
        	header#header {
        		.mobile-nav {
        			background: map-fetch($config, skins $skin primary  );
        		}
        	}
        }

        #slider > ul > li {
          main {
            .pricing-box {
              .pricing-button {
                background: map-fetch($config, skins $skin primary  );
              }
            }
          }

          &:before {
            background-color: map-fetch($config, skins $skin primary);
            background-image: -webkit-linear-gradient(bottom left, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
            background-image: -o-linear-gradient(bottom left, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
            background-image: linear-gradient(to top right, map-fetch($config, skins $skin secondary) 0%, map-fetch($config, skins $skin tertiary) 100%);
          }
        }

        #slidemenu {
          background: map-fetch($config, skins $skin primary  );

          ul {
            li {
              a {
                &:hover {
                  .container-icon {
                      i {
                        color: white;
                      }
                  }
                }
              }

              &.current {
                a {
                  .container-icon {
                      i {
                        color: map-fetch($config, skins $skin primary  );
                      }
                  }
                }
              }
            }
          }
        }

        .nextBtn:hover, .prevBtn:hover {
          background: map-fetch($config, skins $skin primary);
        }

        main#main {
        	.feature {
        		h2 {
        			&:before, &:after {
        			    border-bottom: 1px solid  map-fetch($config, skins $skin primary);
        			}
        		}

        		.service-tabs {
        			.nav-item {
        				&.active {
        					border-color: map-fetch($config, skins $skin primary);

        					.nav-link {
        						color: map-fetch($config, skins $skin primary);
        					}

        					&:after {
        						border-color: map-fetch($config, skins $skin primary) transparent transparent transparent;
        					}
        				}
        			}
        		}

        		.service-tabs-content {
        			h3 {
        				color: map-fetch($config, skins $skin primary);
        			}

        			ul.webicons {
        				li {
        					.icon-container {
        						border: 4px solid map-fetch($config, skins $skin primary);
        						color: map-fetch($config, skins $skin primary);

        						&:hover {
        							background: map-fetch($config, skins $skin primary);
                                    color: white;
        						}
        					}
        				}
        			}
        		}

        		article.news {
        			header {
        				h2 {
        					background-color: map-fetch($config, skins $skin primary);
        				}
        			}
        			footer {
        				.news-btn {
        					background: map-fetch($config, skins $skin primary);

        					&:hover {
        						border-color: map-fetch($config, skins $skin primary);
        						color: map-fetch($config, skins $skin primary);
                                background: white;
        					}
        				}
        			}
        		}
        	}

        	.hero-container {
        		background: map-fetch($config, skins $skin primary);

        		.hero-btn {
        			color: map-fetch($config, skins $skin primary);

        			&:hover {
        				background: map-fetch($config, skins $skin primary);
                        color: white;
        			}
        		}

        		&.invert {
        			color: map-fetch($config, skins $skin primary);
                    background: white;

        			.hero-btn {
        				background: map-fetch($config, skins $skin primary);
        				border-color: map-fetch($config, skins $skin primary);
                        color: white;

        				&:hover {
        					color: map-fetch($config, skins $skin primary);
                            background: white;
        				}
        			}
        		}
        	}
        }

        .study-controls {
          button {
            border: 1px solid map-fetch($config, skins $skin primary);
            color: map-fetch($config, skins $skin primary);

            &:hover,
            &.active {
              background: map-fetch($config, skins $skin primary);
              color: white;
            }
          }
        }
        .study-container {
           .mix {
            .content {
              background: map-fetch($config, skins $skin primary);
            }
          }
           .mix.web-development .content {
            border-top: 4px solid map-fetch($config, skins $skin tertiary);
            .content-overlay {
              .case-btn {
                color: map-fetch($config, skins $skin tertiary);
              }
            }
          }
        }

        footer#footer  {
        	background: map-fetch($config, skins $skin primary);
        }

    }

}
