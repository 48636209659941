#slider {
  position: relative;
  display: block;
  width: 100% !important;
}

#slider ~ main {
  margin-top: 50px;
}

#slider > ul > li {
  min-height: 514px;
  background: url(../images/pattern.png) repeat;
  padding: 40px 0;

  header {
    display: block;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    margin-bottom: 15px;

    h2 {
      font-family: 'Caviar Dreams Bold', 'Lato';
      font-size: 24px;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .subr {
      font-size: 16px;
      font-weight: 300;
      color: white;
    }
  }

  main {
    display: block;
    width: 100%;

    p {
      font-size: 13px;
      font-weight: 300;
      color: white;
    }

    ul.configurations {
      list-style: none;
      display: block;
      padding: 0;
      margin: 0 0 30px 0;

      li {
        display: block;
        margin-bottom: 15px;
        color: white;
        font-size: 13px;
        font-weight: 300;

        span {
          display: inline-block;
          font-weight: 400;
          margin-right: 10px;
          text-align: right;
          width: 25%;
        }
      }
    }

    .pricing-box {
      position: relative;
      display: block;
      width: 100%;
      background: rgba(0,0,0,0.25);
      padding: 10px 15px;

      font-size: 23px;
      font-weight: 400;
      color: white;

      span {
        position: relative;
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
        color: rgba(255,255,255,0.6);
        top: 2px;
        margin-left: 5px;
      }

      .pricing-button {
        font-size: 13px;
        color: white;
        text-decoration: none;
        border-radius: 7px;
        padding: 8px 16px;
        opacity: 0.9;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        text-align: center;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
    z-index: -1;
  }
}


#slidemenu {
  margin: 0 0 50px;

  ul {
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    li {
      position: relative;
      text-align: center;
      border-right: 4px double rgba(255,255,255,0.2);

      a {
        position: relative;
        height: 100%;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 25px 29px 25px 30px;
        text-decoration: none;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;

        .container-icon {
          display: inline-block;
          height: 54px;
          width: 54px;
          border: 2px solid white;
          border-radius: 50%;
          margin-bottom: 16px;
          transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out;

          i {
            position: relative;
            font-size: 32px;
            color: white;
            top: 8px;
            text-align: center;
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: white;
        }

        &:hover {
          background: rgba(255,255,255,0.05);
        }
      }

      &:last-child {
        border-right: 0;
      }

      &.current {
        a {
          background: rgba(255,255,255,0.05);

          .container-icon {
            background: white;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #slidemenu {
    .container {
      width: 100%;
      max-width: 100%;
      padding: 0;

      .row {
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (min-width: 544px) and (max-width: 767px) {
  #slidemenu {
    .container {
      .row {
        li:nth-child(3),
        li:nth-child(4) {
          border-top: 4px double rgba(255,255,255,0.2);
        }

      }
    }
  }
}

#slidemenu li.current a p, #slidemenu li a:hover p {
  color: white;
}

/* --- 5.01 - SLIDER ARROWS --- */
.nextBtn, .prevBtn {
  font-size: 0;
  width: 73px;
  height: 82px;
  background: rgba(41, 41, 41, 0.75);
  margin-top: -81px;
  position: absolute;
  top: 50%;
  z-index: 2;
  cursor: pointer;
}

.prevBtn {
  left: 0;
}

.nextBtn {
  right: 0;
}

.prevBtn:after, .nextBtn:after {
  font-family: "FontAwesome";
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 10px;
}

.prevBtn:after {
  content: "\f104";
  right: 32px;
}

.nextBtn:after {
  content: "\f105";
  left: 32px;
}

.banner {
  position: relative;
  height: 250px;
  width: 100%;
  background-image: url(../images/banner.png);
  background-repeat: none;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
