footer#footer  {
	display: block;
	width: 100%;
	padding: 30px 0;
	background: #2f3948;

	.footercons {
		color: white;
		text-align: center;

		i {
			font-size: 36px;
		}
		span {
			display: block;
			font-size: 13px;
			font-weight: 300;
			text-transform: uppercase;
			margin: 10px 0 30px 0;
		}
	}

	.copyright {
		display: block;
		padding-top: 30px;
		border-top: 4px double rgba(255,255,255,0.2);

		p {
			font-size: 12px;
			font-weight: 400;
			text-transform: uppercase;
			opacity: 0.6;
			color: white;
			text-align: center;
			margin: 0;
		}
	}

}