@font-face {
  font-family: 'Caviar Dreams';
  src: url('../fonts/CaviarDreams/CaviarDreams.ttf')  format('truetype');
}
@font-face {
  font-family: 'Caviar Dreams Italic';
  src: url('../fonts/CaviarDreams/CaviarDreams_Italic.ttf')  format('truetype')
}
@font-face {
  font-family: 'Caviar Dreams Bold';
  src: url('../fonts/CaviarDreams/CaviarDreams_Bold.ttf')  format('truetype')
}
@font-face {
  font-family: 'Caviar Dreams Bold Italic';
  src: url('../fonts/CaviarDreams/CaviarDreams_BoldItalic.ttf')  format('truetype')
}