// Variables
$primary-color: #2f3948 !default;

$nav-link-color: #bbb !default;
$nav-link-active: $primary-color !default;

// Init
body {
	font-family: "Lato", Arial, Helvetica, sans-serif;
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}

p {
	font-size: 13px;
    font-weight: 300;
    color: #a1a1a1;
}

.body-title {
	display: block;
	font-size: 18px;
	font-weight: 400;
	margin: 0;
	padding-bottom: 5px;
	border-bottom: 1px solid rgba(0,0,0,.1);
	margin-bottom: 10px;
}

.img-responsive-center {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    &:empty {
	    left: 50%;
	    -webkit-transform: translate(-50%, 0%);
	    -moz-transform: translate(-50%, 0%);
	    -ms-transform: translate(-50%, 0%);
	    -o-transform: translate(-50%, 0%);
	    transform: translate(-50%, 0%);
	}
}

.padding-wrap {
	padding: 0 50px;
}

.absolute-image {
  position: relative;
  top: -80px;
}

.col-p0 {
	padding: 0 !important;
}
.pl0 {
	padding-left: 0 !important;
}
.pt25 {
	padding-top: 25px !important;
}
.pt40 {
	padding-top: 40px !important;
}
.mb25 {
	margin-bottom: 25px !important;
}
.mb0 {
	margin-bottom: 0 !important;
}
.cwhite {
	color: white !important;
}

// Imports
@import "header";
@import "slider";
@import "main";
@import "casestudies";
@import "footer";
