header#header {
	display: block;
	position: relative;
	width: 100%;
	height: 120px;
	background: white;

	&.header-nav-static {
	    position: fixed;
	    top: 0;
	    background-color: #fff;
	    padding: 0;
	    border-radius: 0;
		z-index: 99;
	}

	.logo-thumb {
	    position: relative;
	    padding: 15px 0;

		img {
			display: block;
			max-height: 90px;
			width: auto;
		}

	}

	.navbar {
		display: block;
	    border-radius: 0;
	    padding: 32px 0;
	    font-size: 14px;
	    font-weight: 300;

		ul {
			text-transform: uppercase;

			.nav-item {
				.nav-link {
					color: #bbb;
					transition: all .3s ease-in-out;
					-webkit-transition: all .3s ease-in-out;
				}
			}
		}
	}

}

@media (max-width: 991px) {
	header#header {
		.mobile-nav {
			position: absolute;
			top: 100%;
			width: 100%;
			padding: 0;
			z-index: 990;
			font-weight: 300;

			ul {
				padding: 15px 0 30px 0;
				text-transform: uppercase;

				.nav-item {
					display: block;
					float: none !important;
					margin-left: 0 !important;
					border-bottom: 1px solid rgba(255,255,255,.8);

					.nav-link {
						color: rgba(255,255,255,.8);
					}

					&.active,
					&:hover {
						border-color: rgba(255,255,255,1);
						.nav-link {
							color: rgba(255,255,255,1);
						}
					}
				}
			}
		}
	}
}
