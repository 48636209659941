.study-controls {
  display: block;
  text-align: center;
  margin-bottom: 25px;

  button {
    outline: none;
    border: none;
    background: white;
    padding: 8px 12px;
    border: 1px solid #2f3948;
    margin-right: 5px;
    margin-top: 5px;
    color: #2f3948;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;

    &:hover,
    &.active {
      background: #2f3948;
      color: white;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.study-container {
  text-align: justify;
  -webkit-backface-visibility: hidden;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
  }

   .mix {
    display: none;
    margin-bottom: 2%;

    .content {
      position: relative;
      color: white;
      background: #2f3948;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      overflow: hidden;

      .project-img {
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          display: block;
          overflow: hidden;
        }
      }
      .content-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 100%;
        transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out;
        padding: 25px;
        text-align: center;

        h3 {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 400;
          color: white;
        }

        p {
          font-size: 13px;
          font-weight: 300;
          color: #eee;
        }

        .case-btn {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          border-radius: 25px;
          transition: all .3s ease-in-out;
           -webkit-transition: all .3s ease-in-out;
          padding: 8px 16px;
          background: white;
          text-decoration: none;
          border: 2px solid white;
          margin-top: 0;

          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
   .mix.web-development .content {
    border-top: 4px solid #43cea2;
    .content-overlay {
      background: rgba(67,206,162,1);

      .case-btn {
        color: #43cea2;

        &:hover {
          background: transparent;
        }
      }
    }
  }
   .mix.support-computing .content {
    border-top: 4px solid #3d74ad;
    .content-overlay {
      background: rgba(61, 116, 173, 1);

      .case-btn {
        color: #3d74ad;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .mix.web-development .content:hover,
  .mix.support-computing .content:hover {
    .content-overlay {
      top: 0;
    }
  }

}